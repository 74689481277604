import './LeftSeparator.css'

const LeftSeparator = ({ text, icon = null }) => (
  <h4 variant="h4" style={{ marginBottom: 36 }}>
    {icon}
    <span style={{ marginLeft: 10 }}>{text}</span>
  </h4>
)

export default LeftSeparator

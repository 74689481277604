import LeftSeparator from './LeftSeparator'
import './Projects.css'

const Projects = ({ isMobile }) => {
  return (
    <div className={`whatIveDone ${isMobile ? 'projectsContainerMobile' : ''}`}>
      <LeftSeparator text="What I've done" icon="🛠️" />
      <section class="projects-container">
        <div class="project">
          <h2>🩺 Ting – Tinnitus Management App</h2>
          <p>
            <em>
              A mobile application designed to help users manage tinnitus
              through personalized activities, tracking, and community support.
            </em>
          </p>
          <ul>
            <li>
              📱 <strong>Built with React Native</strong> – A cross-platform
              mobile app providing an intuitive and responsive user experience.
            </li>
            <li>
              ☁️ <strong>Hosted with Firebase</strong> – Leveraged Firebase
              services for authentication, real-time database, and cloud
              storage.
            </li>
            <li>
              🔍 <strong>Personalized Tinnitus Management</strong> – Recommends
              activities and techniques to provide relief, while allowing users
              to add their own.
            </li>
            <li>
              📊 <strong>Daily Check-Ins & Data Visualization</strong> – Users
              log their tinnitus condition daily and view{' '}
              <strong>monthly analytics & trends</strong>.
            </li>
            <li>
              🗺️ <strong>Google Maps Integration</strong> – Helps users find the
              nearest medical consultants for professional tinnitus support.
            </li>
            <li>
              💬 <strong>Community Discussion Boards</strong> – Users can
              connect, share experiences, and discuss tinnitus management
              strategies.
            </li>
          </ul>
          <p>
            <a href="https://github.com/seanRoo/Ting" target="_blank">
              🔗 View on GitHub
            </a>
          </p>
        </div>

        <div class="project">
          <h2>🚌 Bus Time Alexa Skill App</h2>
          <p>
            <em>
              A real-time bus tracking application leveraging AWS cloud
              infrastructure and a Node.js back-end to deliver live transit
              updates.
            </em>
          </p>
          <ul>
            <li>
              🚀 <strong>Serverless Architecture with AWS</strong> – Built using{' '}
              <strong>AWS Lambda, API Gateway, and DynamoDB</strong> for a
              highly scalable back-end.
            </li>
            <li>
              🔄 <strong>Real-time Data Processing</strong> – Integrated with
              external transit APIs to fetch live bus schedules and arrival
              times dynamically.
            </li>
            <li>
              🛠️ <strong>Node.js Back-End Development</strong> – Developed the{' '}
              <a href="https://github.com/seanRoo/bus-time-app">bus-time-app</a>{' '}
              using <strong>Express.js</strong> to handle API requests and data
              processing.
            </li>
            <li>
              🌐 <strong>Voice Assistant Integration (Alexa Skill)</strong> –
              Designed for hands-free accessibility, allowing users to query bus
              times via voice commands.
            </li>
          </ul>
          <p>
            <a href="https://github.com/seanRoo/bus-time-app">
              🔗 View on GitHub
            </a>
          </p>
        </div>
        <div class="project">
          <h2>🎮 JavaScript 2D Game</h2>
          <p>
            <em>
              A small browser-based game built with vanilla JavaScript,
              exploring simple game mechanics, animations, and player
              interactions.
            </em>
          </p>
          <ul>
            <li>
              🕹️ <strong>Learning 2D Game Development</strong> – A personal
              experiment to understand the fundamentals of game loops, physics,
              and rendering in JavaScript.
            </li>
            <li>
              🎨 <strong>Basic Sprite-Based Graphics</strong> – Uses custom
              assets for the player, enemies, and background.
            </li>
            <li>
              ⚡ <strong>Smooth Animations & Movement</strong> – Implemented
              with <code>requestAnimationFrame()</code> for better performance.
            </li>
            <li>
              🎯 <strong>Collision Detection & Interactions</strong> – Exploring
              different ways to handle player and enemy interactions.
            </li>
            <li>
              🛠️ <strong>Still a Work in Progress</strong> – Currently refining
              mechanics and adding more features.
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}

export default Projects

import {
  faUser,
  faLaptopCode,
  faUniversalAccess,
  faFutbol,
} from '@fortawesome/free-solid-svg-icons'
import {
  ReactJs,
  Angular,
  Testinglibrary,
  Javascript,
  Typescript,
  Cplusplus,
  Csharp,
  Codeceptjs,
  Firebase,
  Mongodb,
  Postgresql,
  Html5,
  CssThree,
  Nodedotjs,
  Python,
  Stackoverflow,
  Git,
  Jenkins,
  Github,
  Bamboo,
  Jira,
} from '@icons-pack/react-simple-icons'
import { FaJava } from 'react-icons/fa'

export const hobbies = [
  {
    icon: '⚽',
    title: 'Football',
  },
  {
    icon: '🏋️‍♂️',
    title: 'Gym',
  },
  {
    icon: '✈️',
    title: 'Travel',
  },
  {
    icon: '🏕️',
    title: 'Camping',
  },
  {
    icon: '💻',
    title: 'Tech',
  },
  {
    icon: '👨‍💻',
    title: 'Coding',
  },
  {
    icon: '🍕',
    title: 'Food',
  },
  {
    icon: '🍳',
    title: 'Cooking',
  },
  {
    icon: '🍺',
    title: 'Socialising',
  },
]

export const skills = [
  {
    title: 'TypeScript/JavaScript',
    icons: [
      { value: Typescript, color: '#3178c6', size: 36 },
      { value: Javascript, color: '#F1DE4F', size: 36 },
    ],
    body: 'Before diving into UI frameworks, I built a strong foundation in JavaScript fundamentals during university and have continued to use it daily in both my professional work and side projects. For the past four years, I have worked exclusively with TypeScript in a professional setting, leveraging its type safety, scalability, and maintainability to build robust applications. TypeScript has become an essential part of my workflow, allowing me to write more reliable and efficient code while improving developer experience across projects.',
  },
  {
    title: 'React, React Native & Angular',
    icons: [
      { value: ReactJs, color: '#61dbfb' },
      { value: Angular, color: 'red' },
    ],
    body: 'My journey with front-end frameworks began with Angular 2, which I used extensively in my Final Year Project during college. After entering the industry, I quickly realized that React was the way forward and made it my framework of choice. Over the past three years, I’ve also worked with the Sencha framework, building complex, data-driven applications. A significant portion of my professional experience has involved rewriting and modernizing legacy codebases, migrating applications from Angular and Sencha to React to improve performance, maintainability, and developer experience. Today, I use React daily in my current role and side projects, and I’ve recently started exploring React Native to expand my expertise in mobile development.',
  },
  {
    title: 'React Testing Library & Enzyme',
    icons: [{ value: Testinglibrary, color: 'red' }],
    body: "I gained all front end testing knowledge through on-the-job learning and feel like I have a good understanding of good & bad practices when it comes to writing unit & integration tests. I first worked with Enzyme but soon became familar with React Testing Library and grew fond of their testing ethos compared to Enzyme. I've found that when coupled with Mock Service Worker, RTL can provide huge amounts of reliable app test coverage and even allow the removal of huge clunky end-to-end test suites.",
  },
  {
    title: 'Codecept & Protractor',
    icons: [{ value: Codeceptjs, color: 'gold' }],
    body: 'I have written a lot of end-to-end tests in both Codecept and Protractor and I believe they do have their place when testing apps, I do tend to prefer providing code coverage with integrations or units though.',
  },
  {
    title: 'Firebase, MongoDB & PostgreSQL',
    icons: [
      { value: Firebase, color: 'orange' },
      { value: Mongodb, color: 'green' },
      { value: Postgresql, color: 'darkblue' },
    ],
    body: "I really have only worked on the back-end side of things in college and on personal projects but I would never be shy about using one if needed. I was always working with SQL Databases throughout college, especially in my Spatial Databases Postgraduate class. My final year project was a MEAN application so I worked a log with Mongo also. Currently getting to know Firebase in the app I'm developing, Ting!",
  },
  {
    title: 'NodeJs, Java, C# and more',
    icons: [
      { value: Nodedotjs, color: 'green' },
      { value: FaJava, color: 'blue' },
      { value: Csharp, color: 'purple' },
      { value: Python, color: '#456E9C' },
      { value: Cplusplus, color: 'darkblue' },
    ],
    body: 'Ranked by familiarity! I work with Node.js almost daily, making it my most-used language. For the past three years, I’ve also worked extensively with Java, and in my current role, I use it in the REST layer to connect backend services and ensure seamless communication between front-end and back-end. While I haven’t used C# as much recently, I do have some industry experience with it. I’m also familiar with Python and have been exploring Machine Learning concepts through various courses, gradually expanding my knowledge in that space.',
  },
  {
    title: 'HTML & CSS, Web Accessibility',
    icons: [
      { value: Html5, color: '#DE6E3C' },
      { value: CssThree, color: '#53A7DC' },
      {
        value: faUniversalAccess,
        color: 'blue',
        isAlternateIcon: true,
      },
    ],
    body: 'I have experience developing WCAG 2.1-compliant software, ensuring accessibility for users of all abilities. My work involves writing semantic HTML, ARIA attributes, and keyboard navigable interfaces, as well as optimizing color contrast and focus management. I also ensure applications are compatible with screen readers and assistive technologies, making them more accessible to a wider range of users.',
  },
  {
    title: 'Stack Overflow',
    icons: [{ value: Stackoverflow, color: 'orange' }],
    body: `Like most developers, I credit my degrees to this community (Maybe YouTube tutorials should also take some credit). StackOverflow has also helped me feel a
      little bit less of an imposter in my few years as a Software Engineer, so it deserves that credit too!`,
  },
  {
    title: 'Developer Tools',
    icons: [
      { value: Git, color: '#f14e32' },
      { value: Github, color: 'black' },
      { value: Jenkins, color: '#D24939' },
      { value: Jira, color: 'blue' },
      { value: Bamboo, color: 'green' },
    ],
    body: (
      <ul style={{ paddingLeft: 20 }}>
        <li>Git</li>
        <li>GitHub</li>
        <li>Jenkins</li>
        <li>Jira</li>
        <li>Bamboo</li>
        <li>Stash</li>
      </ul>
    ),
  },
]

export const sidenavTabs = [
  {
    text: 'About Me',
    icon: faUser,
    ref: 'aboutMeRef',
  },
  { text: 'Tech', icon: faLaptopCode, ref: 'portfolioRef' },
  { text: 'Hobbies', icon: faFutbol, ref: 'hobbiesRef' },
]

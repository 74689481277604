import { hobbies } from '../../utils'

const HobbyList = () => (
  <div class="what-i-like-container">
    <div class="interests-grid">
      {hobbies.map((hobby) => (
        <div class="interest">
          <div class="interest-icon">{hobby.icon}</div>
          <p>{hobby.title}</p>
        </div>
      ))}
    </div>
  </div>
)

export default HobbyList

import LeftSeparator from '../LeftSeparator'
import './SkillList.css'

const SkillListHeader = () => (
  <div className="whatIDo">
    <LeftSeparator text="What I do" icon={'🚀'} />
    <p>
      <em>A bit about my expertise and focus.</em>
    </p>
    <p>
      I’ve been building
      <strong> modern, scalable front-end applications</strong> since 2014, with
      a focus on <strong>React, TypeScript, and JavaScript</strong>. Clean code,
      performance, and intuitive user experiences are at the core of my
      development approach.
    </p>

    <p>
      Over the last three years, I’ve also worked extensively with{' '}
      <strong>Java</strong>, contributing to the <strong>REST layer</strong> of
      our platform—developing and optimizing APIs, ensuring seamless front-end
      integration, and improving system scalability.
    </p>

    <p>
      <strong>
        Below are the technologies I use daily to bring projects to life.
      </strong>
    </p>
  </div>
)

export default SkillListHeader

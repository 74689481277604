import HobbyList from './HobbyList'
import './HobbySection.css'

const HobbySection = ({ isMobile }) => {
  return (
    <div
      className={`hobbySectionContainer ${
        isMobile ? 'hobbySectionMobile' : ''
      }`}
    >
      <h2>What I Like</h2>
      <HobbyList isMobile={isMobile} />
    </div>
  )
}

export default HobbySection

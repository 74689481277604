import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { skills } from '../../utils'
import './SkillList.css'

const SkillList = ({ isMobile }) => (
  <ul className="skills-container">
    {skills.map((skill, index) => (
      <>
        <li
          className="skill-card"
          style={{
            marginBottom: index === skills.length - 1 && isMobile ? 0 : 20,
          }}
        >
          <div style={{ flexDirection: 'row', gap: 4, display: 'flex' }}>
            {skill.icons?.map((icon) =>
              icon?.isAlternateIcon ? (
                <FontAwesomeIcon
                  icon={icon.value}
                  color={icon.color}
                  size="2x"
                  className="skill-icon"
                />
              ) : (
                <icon.value
                  className="skill-icon"
                  size={28}
                  color={icon.color}
                />
              )
            )}
          </div>
          <span className="skill-title">{skill.title}</span>
          <p className="skill-description">{skill.body || null}</p>
        </li>
      </>
    ))}
  </ul>
)

export default SkillList
